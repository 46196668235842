<template>
  <div>
  <el-dialog
      :title="'Patient #' + patient_id + ' aus AIS in DACY übernehmen'"
      v-model="showForm"
      :before-close="closeDialog">
    Der aktuelle Patient im AIS wurde in DACY nicht gefunden. Ergänzen Sie bitte die Stammdaten um den Patienten mit der ID {{patient_id}} in DACY zu übernehmen.
    <el-row class="mt-4">
      <el-col :span="11">
        <h6>Vorname *</h6>
        <el-input
            placeholder="Vorname"
            v-model="newPatient.firstname"></el-input>
      </el-col>
      <el-col :span="12" :offset="1">
        <h6>Nachname *</h6>
        <el-input
            placeholder="Nachname"
            v-model="newPatient.name"></el-input>
      </el-col>
    </el-row>
    <el-row class="mt-4">
      <el-col :span="7">
        <h6>Geburtstag *</h6>
        <el-date-picker
            class="w-100"
            v-model="newPatient.birthday"
            type="date"
            value-format="YYYY-MM-DD"
            format="DD.MM.YYYY"
            placeholder="Datum auswählen">
        </el-date-picker>
      </el-col>
      <el-col :span="7" :offset="1">
        <h6>Geschlecht *</h6>
        <el-select size="medium" class="w-100" placeholder="Bitte auswählen" v-model="newPatient.sex">
          <el-option label="Weiblich" value="w"></el-option>
          <el-option label="Männlich" value="m"></el-option>
        </el-select>
      </el-col>
      <el-col :span="8" :offset="1">
        <h6>Behandler</h6>
        <el-select size="medium" class="w-100" v-model="newPatient.practitioner" filterable>
          <el-option :value="null">-- Kein Behandler ausgewählt --</el-option>
          <el-option
            v-for="item in practitioner"
            :key="item.id"
            :label="item.title"
            :value="item.code">
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row class="mt-4">
      <el-col :span="7">
        <h6>Krankenkassennummer</h6>
        <el-input
          placeholder="Krankenkassennummer"
          v-model="newPatient.insurance_member_number"></el-input>
      </el-col>

    </el-row>

    <el-row class="mt-4">
      <el-col :span="7">
        <h6>Straße</h6>
        <el-input placeholder="Straße" v-model="newPatient.address.street"></el-input>
      </el-col>
      <el-col :span="3" :offset="1">
        <h6>Hausnr.</h6>
        <el-input placeholder="Hausnummer" v-model="newPatient.address.street_no"></el-input>
      </el-col>
      <el-col :span="3" :offset="1">
        <h6>PLZ</h6>
        <el-input placeholder="PLZ" v-model="newPatient.address.zip"></el-input>
      </el-col>
      <el-col :span="8" :offset="1">
        <h6>Ort</h6>
        <el-input placeholder="Ort" v-model="newPatient.address.city"></el-input>
      </el-col>
    </el-row>
    <el-row class="mt-4">

    </el-row>

    <template #footer>
      <el-row>
        <el-col :span="10" class="dialog-footer text-left">
          * Pflichtfeld
        </el-col>
        <el-col :span="14" class="dialog-footer text-right">
            <el-button @click="closeDialog">Abbrechen</el-button>
            <el-button type="primary" @click="savePatientForm()">Patient in DACY übernehmen</el-button>
        </el-col>
      </el-row>

    </template>
  </el-dialog>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from "moment";

export default {
  name: "PatientNewForm",
  props: ['modelValue', 'show', 'patient_id', 'gdt'],
  components: {},
  data() {
    return {
      showForm: false,
      newPatient: {
        address: {}
      },
      allDiagnosis: [],
      allTreatmentSituations: []
    }
  },
  mounted: async function () {
    this.showForm = this.modelValue
    this.newPatient.id = this.patient_id
    await this.getPractitioner()
  },
  computed: {
    ...mapGetters('practitioner', ['practitioner']),
  },
  watch: {
    modelValue: function (val) {
      this.showForm = val
      this.newPatient.id = this.patient_id
      this.newPatient.firstname = this.gdt.patient ? this.gdt.patient.firstName : ''
      this.newPatient.name = this.gdt.patient ? this.gdt.patient.lastName : ''
      this.newPatient.birthday = this.gdt.patient ? this.gdt.patient.birthday : ''
      this.newPatient.insurance_member_number = this.gdt.patient ? this.gdt.patient.insurance_member_number : ''
      this.newPatient.address = {
        street: this.gdt.patient.street ? this.gdt.patient.street.trim() : '',
        street_no: this.gdt.patient.street_no ? this.gdt.patient.street_no.trim() : '',
        zip: this.gdt.patient.zip ? this.gdt.patient.zip.trim() : '',
        city: this.gdt.patient.city ? this.gdt.patient.city.trim() : '',
        country : 'DE'
      }
    }
  },
  methods: {
    ...mapActions('practitioner', ['getPractitioner']),
    ...mapActions('patients', ['saveNewPatient', 'setCurrentPatient']),
    closeDialog() {
      this.$emit('update:modelValue', false)
    },
    async savePatientForm() {

      if(this.newPatient.firstname == null || this.newPatient.name == null || this.newPatient.birthday == null || this.newPatient.sex == null) {
        this.$message({
          message: 'Bitte alle Felder ausfüllen',
          type: 'error'
        });
        return false
      }

      if(this.newPatient.address.street) this.newPatient.address.street = this.newPatient.address.street.trim()
      this.newPatient.birthday = moment(this.newPatient.birthday).format("YYYY-MM-DD")

      const result = await this.saveNewPatient(this.newPatient)
      if(!result) {
        this.$notify({
          message: 'Der Patient konnte in DACY nicht gespeichert werden',
          type: 'error',
          position: 'bottom-left'
        });
      }
      else {

        await this.setCurrentPatient(result.id)
        this.$notify({
          message: 'Patient wurde erfolgreich in DACY übernommen',
          type: 'success',
          position: 'bottom-left'
        });
        this.$emit('after-save')
        this.closeDialog()

      }


    }
}
}
</script>
<style scoped>
</style>
